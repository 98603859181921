import { Skeleton } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '60px'
    },
    boxWrapper: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        width: "calc(100vw - 40vw)",
        minHeight: 'calc(100vh - 50vh)',
        borderRadius: "20px",
        padding: '4%',
        wordWrap: 'break-word',
        marginBottom: '5%',
        '& .MuiSkeleton-root': {

        }
    }
}))
const PageSkeleton = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            <div className={classes.root}>
                <div className={classes.boxWrapper}>
                    <Skeleton height={60} width="100%" />
                    <Skeleton height={200} width="100%" />
                    <Skeleton height={60} width="70%" />
                    <Skeleton height={60} width="70%" />
                </div>
            </div>
            
        </>
    )
}

export default PageSkeleton;

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { common } from "@mui/material/colors";
import PoppinsBold from ".././assets/fonts/Poppins/Poppins-Bold.ttf";

let theme = createTheme({
    palette: {
        primary: {
            light: common.white,
            main: common.white,
            dark: '#192171'
        }
    },
    typography: {
        fontFamily: "'Poppins', sans-serif !important"
    },
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 500;
                    src: local('Poppins'), local('Poppins-Bold'), url(${PoppinsBold}) format('ttf');
                    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                  // Some CSS
                  fontSize: '24px',
                  background: '#192171',
                  padding: '10px 50px 10px 50px',
                  color: '#fff',
                  borderRadius: '20px',
                  fontWeight: 'bold',
                  lineHeight: '36px',
                  transition: '0.5s',
                  minWidth: '460px'
                },
              },
        },
        MuiLink: {
            styleOverrides: {
                // Name of the slot
                root: {
                  // Some CSS
                  fontSize: '24px',
                  background: '#192171',
                  padding: '10px 50px 10px 50px',
                  color: '#fff',
                  borderRadius: '20px',
                  fontWeight: 'bold',
                  lineHeight: '36px',
                  transition: '0.5s',
                  minWidth: '460px',
                  textDecorationLine: 'none',
                  textAlign: 'center',
                  '&:hover': {
                    background: 'rgba(255, 255, 255, 0.1)'
                  }
                },
              },
        }
    }
})


export default theme = responsiveFontSizes(theme);
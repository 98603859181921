import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MainLogo from "../assets/logo/TechmateLogo.png";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& img': {
            marginTop: '14px',
            marginLeft: '43px',
            cursor: 'pointer'
        }
    }
}))

const Header = () => {
    const classes = useStyles();
    let navigate = useNavigate();

    const goHome = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        navigate("/", { replace: true });
    }
    
    return (
        <>
            <div className={classes.root} >
                <img src={MainLogo} alt={'Logo'} onClick={goHome}/>
            </div>   
        </>
    )
}

export default Header;
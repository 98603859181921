import { Snackbar, Alert, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { SyntheticEvent } from "react";


interface SnackAlertProps {
    open: boolean;
    message: string | null;
    severity: 'error' | 'warning' | 'info' | 'success';
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        
    }
}))

const SnackAlert = (props: SnackAlertProps) => {
    const classes = useStyles();

    return (
        <>
            <Snackbar open={props.open} autoHideDuration={6000} onClose={props.handleClose}>
                <Alert onClose={props.handleClose} severity={props.severity} sx={{ width: '100%' }}>
                    {props.message ? props.message: 'Unexpected error happened'}
                </Alert>
            </Snackbar>
        </>
    )
}

export default SnackAlert;
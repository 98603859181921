import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles";
import Footer from "./components/Footer"
import Header from "./components/Header"

interface AppProps {
  childComponent: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'linear-gradient(180deg, #000537 0%, #192171 100%)',
    minHeight: '100vh',
    flexDirection: 'column',
    fontSize: 'calc(10px + 2vmin)',
    '& .MuiTypography-root': {
      color: theme.palette.primary.light
    }
  }
}));

const App = (props: AppProps) => {
  const classes = useStyles();
  return (
      <div className={classes.root}>
        <Header></Header>
          {props.childComponent}
        <Footer></Footer>
      </div>
  )
}

export default App

import { Button, Grid, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { PRESALE_VESTING_CONTRACT_ADDRESS, TMT_CONTRACT_ADDRESS } from "../utils/constants/contract";
// import techmateTokenABI from "../contract/ABI/techmateToken.json";
import presaleVesting from "../contract/ABI/presaleVesting.json";
// import moment from "moment";
import { LoadingButton } from "@mui/lab";
import PageSkeleton from "../components/skeletons/PageSkeleton";
import SnackAlert from "../components/common/SnackAlert";

type address = string;

interface UserInfo {
    totalPurchased: any;
    totalRemainingForClaim: any;
    totalAmountWithdrawn: any;
    totalAmountWithdrawable: any;
}

// interface ContractErrorData {
//     code: number;
//     data: string;
//     message: string;
// }

// interface ContractError {
//     code: number;
//     message: string;
//     data: ContractErrorData;
//     stack: string;
// }

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '60px',
    },
    boxWrapper: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        width: "calc(100vw - 40vw)",
        minHeight: 'calc(100vh - 50vh)',
        borderRadius: "20px",
        padding: '4%',
        wordWrap: 'break-word',
        marginBottom: '10%',
        '& .MuiLoadingButton-root':{
            fontSize: '24px',
            background: '#192171',
            padding: '10px 50px 10px 50px',
            color: '#fff',
            borderRadius: '20px',
            fontWeight: 'bold',
            lineHeight: '36px',
            transition: '0.5s',
            minWidth: '460px',
            '&:hover':{
                
            }
        },
        '& .MuiLoadingButton-loadingIndicator': {
            color: '#FFF !important'
        },
        '& .MuiTypography-h3': {
            textAlign: 'center',
            lineHeight: '54px',
            fontSize: '36px',
            paddingBottom: '20px',
            fontWeight: 700
        },
        '& .MuiTypography-subtitle1': {
            textAlign: 'center',
            lineHeight: '27px',
            fontSize: '18px',
            fontWeight: 400
        },
        [theme.breakpoints.down('sm')]:{
            width: "calc(100vw - 20vw)",
            minHeight: 'calc(100vh - 40vh)',
            '& .MuiButton-root': {
                marginTop: '15px',
                fontSize: '15px',
                background: '#192171',
                padding: '5px 10px 5px 10px',
                color: '#fff',
                borderRadius: '20px',
                fontWeight: 'bold',
                lineHeight: '36px',
                transition: '0.5s',
                minWidth: '200px'
            },
            '& .MuiTypography-h3': {
                textAlign: 'center',
                lineHeight: '30px',
                fontSize: '20px',
                paddingBottom: '20px',
                fontWeight: 700
            },
            '& .MuiTypography-subtitle1': {
                textAlign: 'center',
                lineHeight: '24px',
                fontSize: '15px',
                fontWeight: 400,
                '& a': {
                    color: '#68c6e3'
                }
            }
        }
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        margin: '25px'
    },
    calcWrapper: {
        padding: '4%',
        textAlign: 'center'
    },
    valWrapper: {
        borderRadius: '20px',
        background: 'transparent',
        border: '1px solid white',
        padding: '2%',
        marginTop: '2%',
        marginBottom: '2%'
    }
}));

const PrivateSale = () => {
    const classes = useStyles();
    const theme = useTheme();
    let width = window.innerWidth;

    const dappUrl = "192.168.10.125";
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    
    const [loading, setLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [connectButtonText, setConnectButtonText] = useState("Connect your wallet");
    const [defaultAccount, setDefaultAccount] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const [userInfo, setUserInfo] = useState<UserInfo>({
        totalPurchased: 0,
        totalRemainingForClaim: 0,
        totalAmountWithdrawn: 0,
        totalAmountWithdrawable: 0
    })

    const [, setProvider] = useState<any>(null);
    const [, setSigner] = useState<any>(null);
    const [contract, setContract] = useState<any>(null);

    const isMobileDevice = () => {
        return 'ontouchstart' in window || 'onmsgesturechange' in window;
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(!open);
    };

    const connectWalletHandler = async () => {

        try{
            if(window.ethereum) {
                let done = await window.ethereum.request({method: 'eth_requestAccounts'});
                
                if(done) {
                    accountChangeHandler(done[0]);
                    setConnectButtonText('Wallet Connected')
                }
    
            } else if (isMobile) {
                if(window.ethereum) {
                    let done = await window.ethereum.request({method: 'eth_requestAccounts'});
                    
                    if(done) {
                        accountChangeHandler(done[0]);
                        setConnectButtonText('Wallet Connected')
                    }
        
                }

            } else {
                setErrorMessage('Need to install MetaMask!');
                setTimeout(() => {
                    window.location.replace('https://metamask.io/');
                }, 2000);
            }
        } catch (e) {
            let error: any = e;
            setOpen(true);
            setToastMessage(error?.data.message);
        }
    }

    const accountChangeHandler = async (newAccount: string) => {
        setButtonLoading(true);
        setTimeout(() => {
            setDefaultAccount(newAccount);
            updateEthers();
            setButtonLoading(false);
        }, 1000);
        
    }

    const updateEthers = async () => {
        let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(tempProvider);
        
        let tempSigner = tempProvider.getSigner();
        setSigner(tempSigner);

        let tempContract = new ethers.Contract(PRESALE_VESTING_CONTRACT_ADDRESS, presaleVesting as any, tempSigner)
        // let tempContract = new ethers.Contract(TMT_CONTRACT_ADDRESS, techmateTokenABI as any, tempSigner)
        setContract(tempContract);
    }

    const getUserTotalPurchaseAmount = async (address: address) => {
        let response = await contract.getUserTxLength(address);
        let pendingReward = await contract.getAllUserPending(address);

        let userTransactionLength = convertBigNumber(response, false);
        let totalPurchasedAmountByUser = 0;
        let totalAmountReceived = 0
        let remainingTMTInContract = 0;
        let totalPendingReward = 0;

        for(let i=0;i < userTransactionLength; i++) {
            let userTx = await contract.userTxList(address, i)
            let totalAmountAllowed = Number(convertBigNumber(userTx[3]));
            let amountReceived = Number(convertBigNumber(userTx[4]));
            totalPurchasedAmountByUser += totalAmountAllowed;
            totalAmountReceived += amountReceived;
        }

        remainingTMTInContract = totalPurchasedAmountByUser - totalAmountReceived;
        totalPendingReward = Number(convertBigNumber(pendingReward));

        setUserInfo(prev => ({
            ...prev,
            totalPurchased: totalPurchasedAmountByUser,
            totalRemainingForClaim: remainingTMTInContract,
            totalAmountWithdrawn: totalAmountReceived,
            totalAmountWithdrawable: totalPendingReward
        }))
    }

    const convertBigNumber = (val: any, weird: boolean = true) => {
        let conversion = ethers.utils.formatEther(val).toString();

        if(!weird) {
            let converted: number = Math.round(parseFloat(conversion) * (10 ** 18))

            return converted;
        }
    
        return conversion;
    }

    const vestingWithdraw = async (id: number) => {
        try {
            let response = await contract.withdraw(id);
            if(response) {
                setOpen(true);
                setToastMessage('Successfully withdrawned')
            }
        } catch(e) {
            let error: any = e;
            setOpen(true);
            setToastMessage(error?.data.message)
        }
    }

    // const convertToBoolean = (val: number) => {
    //     return 1 ? true: false;
    // }

    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return () => {};
    });

    useEffect(() => {
        
        if(defaultAccount) {
            // getUserInfo(defaultAccount, 0);
            getUserTotalPurchaseAmount(defaultAccount);
        }
      
        return () => {
            
        }
    }, [contract])
    
    useEffect(() => {
        
        if(window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts: any) => {
                // console.log(accounts[0]);
                accountChangeHandler(accounts[0]);
                setConnectButtonText('Wallet Connected')
            })
        }

        return () => {
            
        }
    }, [window])

    useEffect(() => {
      
        if(width < theme.breakpoints.values.sm) {
            setIsMobile(true);
        }

        return () => {
            
        }
    }, [theme.breakpoints.values.sm, width])
    

    return (
        <>
            { loading && <PageSkeleton />}

            { !loading && 
                <div className={classes.root}>
                    <div className={classes.boxWrapper}>
                        <Typography variant="h3" color="initial">Private Sales Withdrawal Form</Typography>
                        <Typography variant="subtitle1" color="initial">The Techmate Token contract address is {TMT_CONTRACT_ADDRESS} and we are ONLY on the Binance Smart Chain (BSC) Network. Always ensure that you are interacting with the correct token contract address.</Typography>
                        
                        <div className={classes.buttonWrapper}>

                        { isMobile && (
                            <a href={metamaskAppDeepLink}>
                                <LoadingButton 
                                    loading={buttonLoading}
                                >
                                    {defaultAccount}{defaultAccount ? <br/>: ''}{errorMessage ? errorMessage: connectButtonText + "(Mobile)"}
                                </LoadingButton>
                            </a>
                        )}

                        { !isMobile && (
                            <LoadingButton
                                onClick={connectWalletHandler}
                                loading={buttonLoading}
                            >
                                {defaultAccount}{defaultAccount ? <br/>: ''}{errorMessage ? errorMessage: connectButtonText }
                            </LoadingButton> 
                        )}
                         
                        </div>
                        <Typography variant="subtitle1" color="initial">Connect your wallet to be able to see the correct values.</Typography>
                        <Grid className={classes.calcWrapper} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={6} xs={12}>
                                <Typography variant="h5" color="initial">Total TMT in Contract</Typography>
                                <div className={classes.valWrapper}>
                                    <Typography variant="h5" color="initial">{userInfo.totalPurchased}</Typography>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="h5" color="initial">Remaining TMT</Typography>
                                <div className={classes.valWrapper}>
                                    <Typography variant="h5" color="initial">{userInfo.totalRemainingForClaim}</Typography>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="h5" color="initial">Amount Withdrawn</Typography>
                                <div className={classes.valWrapper}>
                                    <Typography variant="h5" color="initial">{userInfo.totalAmountWithdrawn}</Typography>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant="h5" color="initial">Amount Withdrawable</Typography>
                                <div className={classes.valWrapper}>
                                    <Typography variant="h5" color="initial">{userInfo.totalAmountWithdrawable}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.buttonWrapper}>
                            <Button variant="text" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    vestingWithdraw(0);
                                }}
                            >Withdraw</Button>
                        </div>
                    </div>
                    <SnackAlert open={open} message={toastMessage} severity={'error'} handleClose={handleClose} />
                </div>
                
            }
            
        </>
    )
}

export default PrivateSale;

import { Routes, Route } from "react-router-dom";
import App from "./App";
import Main from "./pages/Main";
import PrivateSale from "./pages/PrivateSale";


const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<App childComponent={<Main />} />} />
            <Route path="private-sales" element={<App childComponent={<PrivateSale />} />} />
        </Routes>
    )
}

export default AppRouter;
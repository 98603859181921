import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PageSkeleton from "../components/skeletons/PageSkeleton";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '60px'
    },
    boxWrapper: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        width: "calc(100vw - 40vw)",
        minHeight: 'calc(100vh - 50vh)',
        borderRadius: "20px",
        padding: '4%',
        wordWrap: 'break-word',
        marginBottom: '5%',
        '& .MuiTypography-h3': {
            textAlign: 'center',
            lineHeight: '54px',
            fontSize: '36px',
            paddingBottom: '20px',
            fontWeight: 700
        },
        '& .MuiTypography-subtitle1': {
            textAlign: 'center',
            lineHeight: '27px',
            fontSize: '18px',
            fontWeight: 400,
            '& a': {
                color: '#68c6e3'
            }
        },
        [theme.breakpoints.down('sm')]:{
            width: "calc(100vw - 20vw)",
            minHeight: 'calc(100vh - 40vh)',
            '& .MuiLink-root': {
                marginTop: '15px',
                fontSize: '15px',
                background: '#192171',
                padding: '5px 10px 5px 10px',
                color: '#fff',
                borderRadius: '20px',
                fontWeight: 'bold',
                lineHeight: '36px',
                transition: '0.5s',
                minWidth: '200px'
            },
            '& .MuiTypography-h3': {
                textAlign: 'center',
                lineHeight: '30px',
                fontSize: '20px',
                paddingBottom: '20px',
                fontWeight: 700
            },
            '& .MuiTypography-subtitle1': {
                textAlign: 'center',
                lineHeight: '24px',
                fontSize: '15px',
                fontWeight: 400,
                '& a': {
                    color: '#68c6e3'
                }
            }
        },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        margin: '25px'
    }
}));

const Main = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return () => {};
      });
    

    return (
        <>
            { loading && (<PageSkeleton />) }

            { !loading && (
                <div className={classes.root}>
                    <div className={classes.boxWrapper}>
                        <Typography variant="h3" color="initial">Techmate Token Withdrawal Page</Typography>
                        <Typography variant="subtitle1" color="initial">Welcome to the Techmate Token (TMT) withdrawal page! If you had purchased Techmate Tokens in the Private Sales or is given airdrop via Loot Box purchases, please click on the respective links below to withdraw your vested tokens. </Typography>
                        <Typography variant="subtitle1" color="initial">Here is a quick guide to the withdrawal, <a href='#'> How to claim your vested TMT tokens?</a></Typography>
                        <div className={classes.buttonWrapper}>
                            <Link href='private-sales'>Private Sales</Link>
                        </div>
                        <div className={classes.buttonWrapper}>
                            <Link href='#'>Loot Box Airdrop(Upcoming)</Link>
                        </div>
                        
                        
                    </div>
                </div>
            ) }
            
        </>
    )
}

export default Main;
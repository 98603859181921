import { ThemeProvider } from '@mui/material/styles';
import React from 'react'
import ReactDOM from 'react-dom'
import theme from './providers/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from "react-router-dom";
import AppRouter from './AppRouter';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
      ethereum:any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
